import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/organisms/seo"
import ContactTemplate from "../components/template/ContactTemplate"


const ContactPage = ({ data }) => {
  return (
    <>
      <Seo title="Contact" />
      <ContactTemplate
        contactData={data.contactQuery}
        optionData={data.optionQuery}
      />
    </>
  )
}
export default ContactPage

export const pageQuery = graphql`
  query {

    contactQuery: wpPage(slug: {eq: "contact"}) {
      contactMainText {
        contactMainText
      }
    }

    optionQuery: allWp {
      edges {
        node {
          themeOptions {
            contactInformation {
              commonAddress
              commonCorporateName
              commonEmailAddress
              commonFaxNumber
              commonGoogleMapUrl
              commonPhoneNum
            }
          }
        }
      }
    }

  }
`
