import React, {useEffect, useState} from "react"
import * as styles from './style.module.sass'

import LogoItem from "../../atoms/LogoItem"
import Copyright from "../../atoms/Copyright"

const Footer = (props) => {
  return(
    <footer
      className={props.isWide==null||props.isWide==false? styles.footer : styles.footer + ' ' + styles.footerWide}
    >
      <Copyright
        layoutName={props.layoutName}
      />
      <div className={styles.footerLogo}>
        <LogoItem
          layoutName={props.layoutName}
        />
      </div>
    </footer>
  )
}

export default Footer
