import React, {useRef} from "react"
import * as styles from './style.module.sass'

import SpPageTitle from "../../atoms/SpPageTitle"
import ContactMainText from "../../atoms/ContactMainText"
import ContactBody from "../../organisms/ContactBody"
import Footer from "../../organisms/Footer"

const ContactTemplate = (props) => {
  const layoutName = useRef('/contact/');

  return (
    <div
      className={styles.container}
      id={"container"}
    >
      <div
        className={styles.containerWrap}
      >
        <SpPageTitle
          layoutName={layoutName.current}
          title={"Contact"}
        />
        <article className={styles.contact}>
          <div className={styles.contactSidebar}>
            <ContactMainText
              layoutName={layoutName.current}
              title={props.contactData.contactMainText.contactMainText}
            />
          </div>
          <div className={styles.contactBody}>
            <div className={styles.contactBodyWrap}>
              <ContactBody
                layoutName={layoutName.current}
                contactInformation={props.optionData.edges[0].node.themeOptions.contactInformation}
              />
            </div>
          </div>
        </article>
        <Footer
          layoutName={layoutName.current}
        />
      </div>
    </div>
  )
}

export default ContactTemplate
