import React from "react"
import * as styles from './style.module.sass'

const ContactMainText = (props) => (
  <h1 className={styles.ContactMainText}>
    {props.title}
  </h1>
)

export default ContactMainText
