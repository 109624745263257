import React from "react"
import * as styles from './style.module.sass'

const Copyright = (props) => (
  <small
    translate="no"
    className={styles.Copyright}
  >
    © nomena inc.
  </small>
)

export default Copyright
